import React from 'react'
import cn from 'classnames'
import Link from 'next/link'

import { DateDailyField } from '@components/DateDailyField'
import { SelectButton } from '@components/SelectButton'
import { useBookTopCharts } from '@hooks/book/useBookTopChart'
import { Right2Icon } from '@components/icons/Right2Icon'
import { TopViewIcon } from '@components/icons/TopViewIcon'
import { TopRankFilterProps } from './interface'

export default function TopRankFilter({
  title,
  type,
  category,
  storyType,
  dateOfWeek,
  storyTypeOptions,
  categoryOptions,
  disableNext,
  disablePrev,
  className = '',
  isSeeAll = false,
  disabledClassName,
  isHomePage,
  handleChangeCategory,
  handleChangeStoryType,
  handleClickNextWeek,
  handleClickPrevWeek,
}: TopRankFilterProps) {
  const { query } = useBookTopCharts()

  return (
    <>
      <div
        className={cn(
          'grid grid-cols-2 desktop-tablet:justify-between desktop-tablet:items-center gap-[20px] mb-[20px] mobile:mx-[21px]',
          className
        )}
      >
        <div
          className={cn('flex space-x-[10px] order-1', {
            'col-span-2': !isHomePage,
          })}
        >
          {type === 'top-view' && (
            <TopViewIcon className='text-primary w-[38px] h-[38px] mobile:w-[24px] mobile:h-[24px]' />
          )}
          {type === 'top-purchase' && (
            <img
              src='/images/maread-active.webp'
              alt='Mareads'
              className='text-primary w-[38px] h-[38px] mobile:w-[24px] mobile:h-[24px]'
            />
          )}
          {isHomePage ? (
            <h2 className='font-mitr topic-base mobile:topic-small dark:text-primary'>
              {title}
            </h2>
          ) : (
            <h1 className='font-mitr topic-base mobile:topic-small dark:text-primary'>
              {title}
            </h1>
          )}
        </div>

        <DateDailyField
          dateOfWeek={dateOfWeek}
          handleClickNextWeek={handleClickNextWeek}
          handleClickPrevWeek={handleClickPrevWeek}
          disabledNext={disableNext}
          disabledPrev={disablePrev}
          className={cn(
            'mobile:col-span-2 mobile:mt-[-10px] desktop-tablet:justify-end mobile:order-3',
            {
              'order-2': isHomePage,
              'order-4': !isHomePage,
            }
          )}
          disabledClassName={disabledClassName}
        />
        <SelectButton
          className='order-3 mobile:col-span-2 mobile:order-4'
          options={storyTypeOptions}
          value={storyType}
          onChange={handleChangeStoryType}
          modeBoxBorderOptionClassName='border-0'
        />

        <Link
          href={`/${type}?bookType=${
            query.bookType
          }&startDate=${query.startDate.toISO({
            includeOffset: false,
          })}&endDate=${query.endDate.toISO({ includeOffset: false })}${
            query.categoryId ? `&categoryId=${query.categoryId}` : ''
          }`}
          legacyBehavior
          prefetch={false}
        >
          <a
            className={cn(
              'flex justify-end items-center cursor-pointer text-secondary dark:text-primary order-4 mobile:order-2',
              {
                hidden: !isSeeAll,
              }
            )}
          >
            <span className='text-[14px] font-mitr font-medium'>
              ดูทั้งหมด&nbsp;
            </span>
            <Right2Icon width='14' height='14' />
          </a>
        </Link>
        <div
          className={cn('w-full order-5 col-span-2', {
            'border-b border-gray-3 dark:border-purple': !isHomePage,
            'bg-dash-spacing bg-size-dash bg-left-center bg-repeat-x pb-[1px]':
              isHomePage,
          })}
        />
      </div>

      <div className='overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar'>
        <SelectButton
          options={categoryOptions}
          value={category}
          onChange={handleChangeCategory}
          selectClassName='mobile:pl-[20px]'
          modeBoxBorderOptionClassName='border-0'
        />
      </div>
    </>
  )
}
