import cn from 'classnames'

import { LeftIcon } from '@components/icons/LeftIcon'
import { RightIcon } from '@components/icons/RightIcon'

import { DateDailyFieldProps } from './interface'

export function DateDailyField({
  dateOfWeek,
  handleClickNextWeek,
  handleClickPrevWeek,
  disabledNext,
  disabledPrev,
  className,
  disabledClassName = '!bg-gray-4',
}: DateDailyFieldProps) {
  return (
    <div className={cn('w-full flex flex-row h-[40px]', className)}>
      <button
        type='button'
        disabled={disabledPrev}
        onClick={handleClickPrevWeek}
        className={cn(
          `flex justify-center items-center rounded-l-[20px] w-[40px] bg-secondary dark:bg-purple cursor-pointer ${
            disabledPrev && disabledClassName
          }`,
          {
            'cursor-no-drop dark:!bg-dark-8': disabledPrev,
          }
        )}
      >
        <span className='text-white dark:text-white-2'>
          <LeftIcon />
        </span>
      </button>
      <div className='flex justify-center items-center desktop-tablet:w-[200px] w-full border-t border-b border-gray-4 dark:border-dark-9 text-[14px] text-secondary desktop-tablet:font-light font-medium bg-white dark:bg-dark-9'>
        <span className='whitespace-pre text-center dark:text-dark-10'>
          {dateOfWeek}
        </span>
      </div>
      <button
        type='button'
        disabled={disabledNext}
        className={cn(
          `flex justify-center items-center rounded-r-[20px] w-[40px] bg-secondary dark:bg-purple cursor-pointer ${
            disabledNext && disabledClassName
          }`,
          {
            'cursor-no-drop dark:!bg-dark-8': disabledNext,
          }
        )}
        onClick={handleClickNextWeek}
      >
        <span className='text-white dark:text-dark-10'>
          <RightIcon className='text-white dark:text-white-2' />
        </span>
      </button>
    </div>
  )
}
