import { useRef } from 'react'
import cn from 'classnames'

import { useDraggable } from '@hooks/draggableScroll/useDraggable'
import { OptionType } from '@interfaces/OptionType'
import { SelectButtonProps } from './interface'

export function SelectButton({
  selectClassName,
  className,
  notSelectOptionClassName,
  modeBoxBorderOptionClassName,
  options,
  value,
  label,
  onChange,
  mode = 'box',
}: SelectButtonProps) {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { events } = useDraggable(ref)

  return (
    <div
      className={cn(
        'text-[14px] text-secondary-1 font-light scrollbar-none scrollbar-none::-webkit-scrollbar mobile:overflow-auto',
        className
      )}
    >
      {label && <div className='font-light text-[12px] mb-[4px] '>{label}</div>}
      <div
        className={cn(
          'flex max-w-full mobile:after:content-[""] mobile:after:grow-[100] text-secondary-1 dark:text-dark-10 overflow-x-auto scrollbar-none scrollbar-none::-webkit-scrollbar',
          selectClassName,
          {
            'space-x-[15px] h-[40px]': mode === 'box',
            'bg-white dark:bg-dark-8 rounded-[8px] border-gray-4 dark:border-purple border mobile:flex-row mobile:flex-wrap':
              mode === 'line',
          }
        )}
        {...events}
        ref={ref}
      >
        {options.map((option: OptionType, index: number) => (
          <div
            className={cn(
              'flex px-[20px] py-[9px] items-center justify-center whitespace-pre cursor-pointer border-rounded desktop:w-max mobile:grow',
              {
                'bg-secondary dark:bg-purple text-white dark:text-white-2 dark:border-purple':
                  value === option.value,
                'bg-gray-4 dark:bg-dark-13 text-secondary-1 dark:text-dark-10 dark:border-dark-13':
                  value !== option.value &&
                  mode === 'box' &&
                  !notSelectOptionClassName,
                'border-[1px] border-gray-3':
                  mode === 'box' && !modeBoxBorderOptionClassName,
                'border-none': mode === 'line',
              },
              notSelectOptionClassName,
              modeBoxBorderOptionClassName
            )}
            key={`${option.value}`}
            onClick={() => onChange && onChange(option.value)}
          >
            {option.icon && <div className='mr-[10px]'>{option.icon}</div>}
            {option.text}
          </div>
        ))}
      </div>
    </div>
  )
}
